<template>
  <div class="calendar-page">
    <v-form>
      <v-container>
        <v-layout row wrap class="pt-0 common-background">
          <v-flex sm12 md12 lg12>
            <v-layout class="pt-0">
              <v-flex class="pa-0" style="font-size: smaller !important;">
                <v-sheet class="pa-0">
                  <template>
                    <v-app-bar class="pa-0 ma-0" app :style="`background: ${ $vuetify.theme.dark ? '' : systemDetails.themecolor }`" tile height="62" width="100%">
                      <div class="d-flex pt-1 px-3 pb-0" style="width: 100%;">
                        <template>
                          <v-layout row wrap align-center class="ma-0">
                            <v-flex xs1>
                              <v-btn fab x-small dark class="pa-0" icon color="#FFFFFF" @click="$router.push('/dashboard')">
                                <v-icon size="20">mdi-arrow-left</v-icon>
                              </v-btn>
                            </v-flex>
                          </v-layout>
                        </template>
                        <template>
                          <v-layout row wrap align-center class="ma-0 text-center">
                            <v-flex xs-9>
                              <v-btn class="ma-1 mr-1 mb-2" outlined fab color="#FFFFFF" x-small @click="previous">
                                <v-icon>mdi-chevron-left</v-icon>
                              </v-btn>
                              <span class="subtitle-1 font-weight-bold mx-2 my-auto white--text">{{ calendarTitle }}</span>
                              <v-btn class="ma-1 mr-1 mb-2" outlined fab color="#FFFFFF" x-small @click="next">
                                <v-icon>mdi-chevron-right</v-icon>
                              </v-btn>
                            </v-flex>
                          </v-layout>
                        </template>
                        <template>
                          <v-layout row wrap align-center class="ma-0 text-end">
                            <v-flex xs-2>
                              <v-btn x-small dark elevation="0" class="pa-2 mr-5" :ripple="false" fab depressed icon color="#FFFFFF" @click="today">
                                <v-icon size="20">mdi-calendar-today</v-icon>
                              </v-btn>
                              <v-btn x-small :ripple="false" dark @click="showFilter = !showFilter" fab icon color="#FFFFFF">
                                <v-icon>mdi-filter-variant</v-icon>
                              </v-btn>
                            </v-flex>
                          </v-layout>
                        </template>
                      </div>
                    </v-app-bar>
                    <template>
                      <v-layout v-show="calendarView == 'month'" class="px-1 pt-3 pa-1" style="margin: unset !important">
                        <v-progress-linear color="primary" v-if="intialPageLoader" indeterminate rounded height="3"></v-progress-linear>
                        <full-calendar id="full_calendar" :config="isActiveAlert ? null : config" :events="isActiveAlert ? [] : events" ref="calendar" :key="getRandomValue"></full-calendar>
                      </v-layout>
                    </template>
                  </template>
                  <div v-if="calendarView === 'agendaWeek' || calendarView === 'agendaDay'" class="set-height">
                    <v-card flat class="pt-0">
                      <v-simple-table v-show="calendarView === 'agendaWeek'" class="mx-1 pa-0" fixed-header height="100">
                        <thead>
                        <tr>
                          <th :id="weekDate" class="mr-1 text-center" v-for="weekDate in weekArray" :key="weekDate">
                            {{ weekDate.split('')[0] }}
                          </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr :class="selectedDate ? 'remove-cell-bg' : ''">
                          <td v-for="date in weekDates" class="px-0 text-center" :key="date" @click="selectedDate = date; getSelectedDateEvents(date)">
                            <span :class="[selectedDate === date ? 'cell-bg-change' : '', $formatter.getCurrentDate('YYYY-MM-DD 00:00') === date ? 'weekview-currentdate' : '']">
                              {{ $formatter.formatDate(date, 'YYYY-MM-DD 00:00', 'DD') }}
                            </span>
                          </td>
                        </tr>
                        </tbody>
                      </v-simple-table>
                      <template>
                        <div :class="calendarView === 'agendaWeek' ? 'agenda-week-custom-class' : 'agenda-day-custom-class pt-1'">
                          <v-simple-table dense>
                            <tbody>
                              <tr v-for="(dayTime, i) in getEventsBetweenHalfHour" :key="i">
                                <td style="width: 30px !important; height: 20px !important;" class="px-1 td-boder-left" :class="dayTime.time.split(':')[1] === '30' ? '' : 'get-solid-border'">
                                  {{ dayTime.time.split(':')[1] === '30' ? '' : dayTime.time }}
                                </td>
                                <td style="height: 20px !important;padding: 0px !important;position: relative !important;" :class="dayTime.time.split(':')[1] === '30' ? '' : 'get-solid-border'" class="create-event" @click="!selectedId && !isActiveAlert ? createEventOrTask(calendarView, selectedDate, eventObj.type, dayTime) : ''">
                                  <template v-for="(item, dataIndex) in dayTime.data">
                                    <v-card class="" flat :key="dataIndex" tile :color="`${item.modulename === 'Event' ? '#039BE5' : '#da1a6e'}`" @click="item.accesscontrol.view ? expandDetailsAboutDate(item) : ''"
                                      :style="getStyleForEvent(item)"
                                      :height="item.height">
                                      <span class="px-2 text-center pb-2 d-inline-block text-truncate" :style="`max-width: ${item.width < 96 ? 60 : 300}px;`">
                                        {{ item.name }}
                                      </span>
                                    </v-card>
                                  </template>
                                </td>
                              </tr>
                            </tbody>
                          </v-simple-table>
                        </div>
                      </template>
                    </v-card>
                  </div>
                  <v-navigation-drawer width="230" right overlay-opacity="0.75" fixed temporary v-model="showFilter">
                    <v-card flat rounded="0" height="100%">
                      <v-list nav dense class="pa-0">
                        <v-list-item-group v-model="customFilterName" v-if="calendarActiveModules.length == 2">
                          <v-list-item-title class="pa-2 text-center font-weight-bold">{{ selectByFilter }}</v-list-item-title>
                          <v-divider></v-divider>
                          <v-list-item :color="$vuetify.theme.dark ? '' : systemDetails.themecolor" class="pa-0 pt-1" v-for="(item, i) in eventsType" :key="i.id" :value="item.id"
                           @click="getEventsAndTasks(item.id); showFilter = !showFilter">
                            <template #default="{ active }">
                              <v-list-item-action class="ma-0 ma-1 checkbox-size">
                                <v-checkbox :input-value="active" class="ma-0 calendar-checkbox" :color="$vuetify.theme.dark ? '' : systemDetails.themecolor"></v-checkbox>
                              </v-list-item-action>
                              <v-list-item-title :class="['text-left px-1 subtitle-2', $vuetify.theme.dark ? 'white--text' : '']"> {{ item.name }} </v-list-item-title>
                            </template>
                          </v-list-item>
                        </v-list-item-group>
                        <v-divider></v-divider>
                        <v-list-item-title class="pa-2 text-center font-weight-bold">{{ listByViews }}</v-list-item-title>
                        <v-divider></v-divider>
                        <v-list-item-group v-model="calendarView">
                          <v-list-item class="pa-1 pb-0"  @click="showFilter= !showFilter" :color="$vuetify.theme.dark ? '' : systemDetails.themecolor" v-for="(item, i) in listOfViews"
                           :key="i.value" :value="item.value">
                            <template #default="{ active }">
                              <v-list-item-action class="ma-0 mx-1">
                                <v-icon :input-value="active" size="20" :color="$vuetify.theme.dark ? '' : systemDetails.themecolor">{{ item.icon }}</v-icon>
                              </v-list-item-action>
                              <v-list-item-content>
                                <v-list-item-title :class="['text-left px-1 subtitle-2', $vuetify.theme.dark ? 'white--text' : '']"> {{ item.text }} </v-list-item-title>
                              </v-list-item-content>
                            </template>
                          </v-list-item>
                        </v-list-item-group>
                        <v-divider></v-divider>
                          <v-list-item :color="$vuetify.theme.dark ? '' : systemDetails.themecolor" class="pa-2" dense @click="getEventsAndTasks(); showFilter= !showFilter">
                            <v-icon :color="$vuetify.theme.dark ? '' : systemDetails.themecolor"> mdi-refresh </v-icon>
                            <v-list-item-title class="px-2 text-left subtitle-2">{{$t('refresh')}}</v-list-item-title>
                          </v-list-item>
                      </v-list>
                    </v-card>
                  </v-navigation-drawer>
                </v-sheet>
              </v-flex>
            </v-layout>
            <v-layout v-if="!isActiveAlert && calendarView === 'month'" row wrap :class="$vuetify.breakpoint.name === 'xs' ? 'event-display-xs-device' : 'event-display-large-device'"
              :style="`background-color:${$vuetify.theme.dark ?'#1e1e1e':''}`">
              <v-flex sm12 md12 lg12 v-if="selectedDateEvent">
                <v-sheet class="pa-2 rounded-lg rounded-b-0" height="100%">
                  <v-progress-linear color="primary accent-4" indeterminate rounded height="2" v-if="intialPageLoader"></v-progress-linear>
                  <div v-if="eventsOnSelectedDay.length">
                    <template v-if="calendarView === 'month'">
                      <v-timeline dense class="pt-7 ml-n5">
                        <template v-for="(event, index) in eventsOnSelectedDay">
                          <v-timeline-item small right v-if="event.accesscontrol.view" :color="event.modulename === 'Event' ? '#039BE5' : '#da1a6e'" :key="index">
                            <template #icon>
                              <v-btn fab height="30" width="30" :color="$vuetify.theme.dark ? '' : (event.modulename === 'Event') ? '#1976d2' : '#da1a6e'" elevation="4">
                                <v-icon size="16" color="#FFFFFF">
                                  {{ event.modulename === 'Event' ? iconForModules.eventIcon : iconForModules.taskIcon }}
                                </v-icon>
                              </v-btn>
                            </template>
                            <template>
                              <v-layout @click="event.accesscontrol.view ? expandDetailsAboutDate(event) : ''" row wrap align-center class="ma-0 mt-0 mx-3">
                                <v-flex xs12 style="line-height: 100%;">
                                  <span :class="['font-weight-bold body-2 text--darken-3', $vuetify.theme.dark ? '' : 'grey--text']">
                                    {{ event.name }}
                                  </span>
                                </v-flex>
                                <v-flex xs12>
                                  <template v-if="event.modulename === 'Event'">
                                    <template v-if="event.start_date && event.end_date">
                                      <p class="caption ma-0" v-if="event.start_date === event.end_date"> {{ `${ getFormattedDate(event.start_date) }` }} </p>
                                      <p class="caption ma-0" v-else> {{ `${ getFormattedDate(event.start_date) } - ${ getFormattedDate(event.end_date) }` }} </p>
                                    </template>
                                  </template>
                                  <template v-else>
                                    <p class="caption ma-0" v-if="event.start_date === event.due_date"> {{ `${ getFormattedDate(event.start_date) }` }} </p>
                                    <p class="caption ma-0" v-else>
                                      <span> {{ `${ getFormattedDate(event.start_date) } - ${ getFormattedDate(event.due_date) }` }} </span>
                                      <v-icon small> mdi-circle-medium </v-icon>
                                      <span class="text-lowercase"> {{ getInBetweenDateCount({ startdate: getFormattedDate(event.start_date), enddate: getFormattedDate(event.due_date) }) }} {{ $t('days') }} </span>
                                    </p>
                                  </template>
                                </v-flex>
                                <v-flex xs12>
                                  <p class="caption ma-0">
                                    {{ (event.start_time && event.end_at) ? (`${event.start_time} - ${event.end_at}`) : (event.due_time || '') }}
                                  </p>
                                </v-flex>
                              </v-layout>
                            </template>
                          </v-timeline-item>
                        </template>
                    </v-timeline>
                    </template>
                  </div>
                  <div v-if="!eventsOnSelectedDay.length && !intialPageLoader" class="pa-0 text-center mt-16">
                    <img width="20.5%" src="@/assets/no-events.png" alt="" />
                    <span class="display-alert-icon">
                      <v-alert dense dark text :color="$vuetify.theme.dark ? '' : systemDetails.themecolor" prominent class="ma-4 pa-1" icon="mdi-calendar-blank" outlined>
                        <v-row align="center">
                          <v-col class="grow">
                            {{ $t('noEventsAlertMsg') }}
                          </v-col>
                        </v-row>
                      </v-alert>
                    </span>
                  </div>
                </v-sheet>
              </v-flex>
            </v-layout>
            <template v-if="isActiveAlert && !eventsOnSelectedDay.length && calendarView === 'month'">
              <v-card tile class="px-7 pt-16">
                <div class="text-center set-custom-height">
                  <img width="20.5%" src="@/assets/no-events.png" alt="" />
                  <span class="display-alert-icon">
                    <v-alert dense dark color="primary" prominent class="pa-1" icon="mdi-calendar-blank" outlined> {{ $t('noEventsAlertMsg') }} </v-alert>
                  </span>
                </div>
              </v-card>
            </template>
            <v-row class="mt-1 float-right">
              <v-speed-dial v-if="calendarView === 'month' && calendarModules.length && (modulePermissions && (modulePermissions.eventModuleAccessControl && modulePermissions.eventModuleAccessControl.add) || (modulePermissions.taskModuleAccessControl && modulePermissions.taskModuleAccessControl.add))"
               v-model="addEvents" right direction="top" transition="slide-y-transition" bottom="bottom" fixed class="calendar_speed_dial_position"  style="z-index: 5">
                <template #activator>
                  <v-btn fab v-model="addEvents" :color="systemDetails.themecolor" dark height="35" width="35">
                    <v-icon size="20" v-if="!addEvents"> mdi-plus </v-icon>
                    <v-icon size="20" v-else> mdi-close </v-icon>
                  </v-btn>
                </template>
                <template>
                  <v-row v-for="(event, index) in calendarModules" :key="index">
                    <v-btn fab v-if="event.accesscontrol.add" elevation="0" :color="event.id == 1 ? 'primary' : '#da1a6e'" class="mt-7"
                    @click="createEventOrTask(calendarView, selectedDate, event.id)" height="35" width="35">
                      <v-icon size="18" color="#FFFFFF"> {{ event.icon }} </v-icon>
                    </v-btn>
                  </v-row>
                </template>
              </v-speed-dial>
            </v-row>
          </v-flex>
        </v-layout>
      </v-container>
    </v-form>
    <v-dialog v-model="dialog" width="1150px" fullscreen hide-overlay transition="dialog-bottom-transition" persistent class="form-alignment">
      <v-card style="overflow: hidden !important;" rounded="0">
        <v-card-title class="pa-2" v-if="moduleObj" :style="!$vuetify.theme.dark ? `background-color:${systemDetails.themecolor};color:${systemDetails.textcolor}` : ''">
          <v-row>
            <v-col cols="2">
              <v-btn fab text icon :color="$vuetify.theme.dark ? '' : '#FFFFFF'" @click="closeDialogHandler()" dark small elevation="0">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="7" class="text-center">
              <span class="subtitle-1"> {{ selectedId ? $t('update') : $t('create')}} {{ moduleObj.moduleName }} </span>
            </v-col>
            <v-col cols="3 text-end">
              <v-btn fab dark small :disabled="!eventObj.type" v-if="selectedId ? recordAccessControl && recordAccessControl.accesscontrol.edit : true" text :loading="loading" @click="saveUpdateHandler"
              :color="$vuetify.theme.dark ? '' : systemDetails.textcolor">
                <v-icon size="20"> mdi-checkbox-marked-circle-outline </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-1 form-height">
          <v-row no-gutters v-if="showFormLoader || fieldsLoading">
            <v-col cols="12" class="pa-2">
              <div v-for="item in 9" :key="item" class="py-1">
                <v-sheet>
                  <v-skeleton-loader class="mx-auto" type="list-item"></v-skeleton-loader>
                </v-sheet>
              </div>
            </v-col>
          </v-row>
          <template v-else>
            <v-row style="{color: gray}" v-show="calendarView !== 'month'">
              <v-col cols="12" lg="12" class="my-2 py-2 pb-4 radio-alignments">
                <v-radio-group v-model="eventObj.type" row hide-details v-if="!selectedId" class="mt-0 pt-1">
                  <v-radio v-for="(n, index) in calendarModules" :key="index" :label="n.name" :value="n.id"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <div class="pa-1 mt-2">
              <module-render v-if="dialog" :listOfFields="listOfFields" :fields="fields" ref="formReferences" :key="reRender" :moduleName="moduleObj.moduleName"
              :eventId="(selectedId || null)" :isRecordCreation="!selectedId"></module-render>
            </div>
          </template>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { FullCalendar } from 'vue-full-calendar'
import 'fullcalendar/dist/locale/nb'
import mixins from '../CustomModules/mixin'
import { mapGetters } from 'vuex'
export default {
  mixins: [mixins],
  data () {
    return {
      dialog: false,
      events: [],
      eventObj: { type: null },
      getRandomValue: 0,
      reRender: 0,
      listOfFields: [],
      fields: {},
      eventModuleId: '',
      taskModuleId: '',
      moduleObj: {},
      calendarTitle: '',
      selectedId: '',
      calendarView: 'month',
      MODULE_URL: 'moduledata',
      showFormLoader: false,
      calendarActiveModules: [],
      customFilter: null,
      isActiveAlert: false,
      intialPageLoader: false,
      dateHolder: {},
      eventsType: [{
        id: 1,
        name: this.$t('event')
      }, {
        id: 2,
        name: this.$t('task')
      }, {
        id: -1,
        name: this.$t('all')
      }],
      loading: false,
      customFilterName: null,
      enumarateDates: [],
      eventsOnSelectedDay: [],
      selectedDateEvent: false,
      selectedDate: '',
      listOfAllEvents: [],
      showFilter: false,
      eventAccessControl: null,
      taskAccessControl: null,
      recordAccessControl: null,
      addEvents: false,
      weekDates: [],
      getEventsBetweenHalfHour: [],
      iconForModules: null,
      fieldsLoading: false,
      modulePermissions: {},
      updatedEventsFromSelectedDate: false
    }
  },
  components: {
    FullCalendar,
    ModuleRender: () => import('../../components/Modules/ModuleFormRender')
  },
  computed: {
    ...mapGetters(['listOfModules', 'userDetails', 'systemDetails']),
    selectByFilter () {
      return this.$t('filterby')
    },
    weekArray () {
      return [0, 1, 2, 3, 4, 5, 6].map(day => this.$moment().locale(this.$i18n.locale).weekday(day).format('dddd'))
    },
    listByViews () {
      return this.$t('views')
    },
    calendarModules () {
      let types = []
      const iconObj = {}
      if (this.listOfModules.length > 0) {
        types = this.listOfModules.filter((element) => {
          if (element.name === 'Event') {
            element.id = 1
            this.modulePermissions.eventModuleAccessControl = element.accesscontrol
            iconObj.eventIcon = element.icon
            return element
          } else if (element.name === 'Task') {
            element.id = 2
            this.modulePermissions.taskModuleAccessControl = element.accesscontrol
            iconObj.taskIcon = element.icon
            return element
          }
        })
        this.getIconsForModules(iconObj)
        /* this.listOfModules.forEach(element => {
          if (element.name === 'Event') {
            types.push({ id: 1, name: this.$t('event'), color: 'primary', icon: 'mdi-calendar', accessControl: element.accesscontrol })
          }
          if (element.name === 'Task') {
            types.push({ id: 2, name: this.$t('task'), color: '#da1a6e', icon: 'mdi-checkbox-marked-circle-outline', accessControl: element.accesscontrol })
          }
        }) */
      }
      return types
    },
    listOfViews () {
      return [
        { value: 'month', text: this.$t('month'), icon: 'mdi-calendar-range' },
        { value: 'agendaWeek', text: this.$t('week'), icon: 'mdi-calendar-text' },
        { value: 'agendaDay', text: this.$t('day'), icon: 'mdi-calendar' }
      ]
    },
    config () {
      return {
        eventLimit: 2,
        displayEventTime: false,
        eventLongPressDelay: 0.5,
        selectLongPressDelay: 0, // For touch devices
        longPressDelay: 0,
        defaultView: 'month',
        selectable: false,
        droppable: false,
        sync: true,
        locale: this.$i18n.locale === 'no' ? 'no' : this.$i18n.locale,
        header: {
          left: '',
          center: '',
          right: ''
        }
      }
    }
  },
  watch: {
    '$i18n.locale': {
      handler (val) {
        if (val) {
          this.getRandomValue++
          setTimeout(() => {
            this.setTitle()
          })
        }
      },
      deep: true
    },
    'eventObj.type' (val) {
      if (val) {
        const name = val === 1 ? this.EVENT : this.TASK
        this.listOfModules.forEach(element => {
          if (element.name === name) {
            this.moduleObj.moduleId = element._id
            this.moduleObj.moduleName = element.name
            if (this.calendarModules.length > 0 && !this.isActiveAlert && !this.dialog) {
              this.selectedDateEvent = true
              this.$formatter.updateMomentLocale(this.$cookie.get(`${process.env.VUE_APP_NAME}_lang`))
            }
          }
        })
        // const result = this.calendarModules.find(x => x.id === val)
        // if (result && result.permission) this.hasManagePermission = true
        // else this.hasManagePermission = false
        // this.listOfFields = []
        this.getFieldsForModule(true)
        const dateFields = this.dateHolder
        this.setFieldsforSelectedDate(dateFields.startDate, dateFields.endDate, dateFields.startTime, dateFields.endTime)
      }
    },
    calendarView (val) {
      this.$refs.calendar.fireMethod('changeView', val)
      this.setTitle()
      this.getEventsAndTasks()
    },
    customFilterName (val) {
      this.customFilter = this.eventsType.find(x => x.id === val)
    }
  },
  mounted () {
    this.$root.$on('listOfFieldsUpdated', () => {
      this.setFormValues()
    })
    this.getSelectedDateStyle()
    this.$root.$on('calendarDateField', () => {
      this.setFieldsforSelectedDate(this.dateHolder.startDate, this.dateHolder.endDate, this.dateHolder.startTime, this.dateHolder.endTime)
    })
    // this.calendarActiveModules = this.listOfModules.filter(x => (x.name === 'Event' && x.isactive && x.accesscontrol.view) || (x.name === 'Task' && x.isactive && x.accesscontrol.view))
    this.calendarActiveModules = this.listOfModules.filter(x => {
      if (x.name === 'Event' && x.isactive && x.accesscontrol.view) {
        this.eventAccessControl = x.accesscontrol
        return x
      } else if (x.name === 'Task' && x.isactive && x.accesscontrol.view) {
        this.taskAccessControl = x.accesscontrol
        return x
      }
    })
    const listOfEvents = this.eventsType.filter((x) => this.calendarActiveModules.find((y) => y.name === x.name))
    if (listOfEvents.length !== 2) this.eventsType = listOfEvents
    this.filterBy = (listOfEvents.length === 2) ? localStorage.getItem(`${process.env.VUE_APP_NAME}_calendar_filter`) ? localStorage.getItem(`${process.env.VUE_APP_NAME}_calendar_filter`) : -1 : null
    if (listOfEvents.length === 1) {
      this.filterBy = listOfEvents[0].id
      localStorage.setItem(`${process.env.VUE_APP_NAME}_calendar_filter`, JSON.stringify(listOfEvents[0].id))
    }
    this.getEventsAndTasks(JSON.parse(this.filterBy))
    this.setTitle()
    this.$root.$on('callRerenderEventForm', () => this.reRender++)
  },
  methods: {
    getIconsForModules (icons) {
      this.iconForModules = icons
    },
    getSelectedDateStyle () {
      const header = document.querySelector('.fc-scroller > .fc-day-grid')
      const days = header.querySelectorAll('.fc-day-number')
      for (let i = 0; i < days.length; i++) {
        days[i].addEventListener('click', (e) => {
          header.querySelectorAll('.fc-day-number').forEach((x) => {
            if (x.classList.contains('fc-highlight')) x.classList.remove('fc-highlight')
          })
          days[i].classList.add('fc-highlight')
          const selectedDate = e.target.parentElement.getAttribute('data-date')
          this.setSeletcedDateToFormFields(selectedDate)
        })
      }
    },
    setSeletcedDateToFormFields (selectedDate) {
      const startDate = this.$formatter.formatDate(selectedDate, 'YYYY-MM-DD', this.userDetails.dateformat)
      const endDate = startDate
      const startTime = this.$moment(startDate).format('hh:mm')
      const endTime = this.$moment(startDate).format('hh:mm')
      this.dateHolder = { startDate, endDate, startTime, endTime }
      this.selectedDate = this.$formatter.formatDate(startDate, this.userDetails.dateformat, 'YYYY-MM-DD HH:mm')
      this.getSelectedDateEvents(this.selectedDate)
      this.setFieldsforSelectedDate(startDate, endDate, startTime, endTime)
    },
    getStyleForEvent (item) {
      const result = this.eventsOnSelectedDay.filter((event) => {
        const eventStartTime = event.start_time ? this.$moment(event.start_time, 'HH:mm') : this.$moment(event.due_time, 'HH:mm')
        const eventEndTime = event.end_at ? this.$moment(event.end_at, 'HH:mm') : this.$moment(event.due_time, 'HH:mm')
        const itemStartTime = item.start_time ? this.$moment(item.start_time, 'HH:mm') : this.$moment(item.due_time, 'HH:mm')
        const itemEndTime = item.end_at ? this.$moment(item.end_at, 'HH:mm') : this.$moment(item.due_time, 'HH:mm')
        return itemStartTime.isBetween(eventStartTime, eventEndTime) || eventStartTime.isBetween(itemStartTime, itemEndTime)
      })
      this.getZIndex(item, result) // For getting differenciation between same time occurance of a event
      return {
        color: '#FFFFFF !important',
        position: 'absolute !important',
        top: `${item.topPosition}px !important`,
        'z-index': 1,
        left: `calc(${item.leftPosition ? item.leftPosition : 0}% + 8px) !important`,
        width: `${item.width ? item.width : (item.leftPosition === 5 ? 90 : 95)}% !important`,
        border: '1px solid #01579B !important',
        'min-height': '20px !important'
      }
    },
    getZIndex (item, listOfOccuredEvents) {
      let zIndex = 0
      listOfOccuredEvents.forEach((event) => {
        if (item.start_time !== event.start_time || item.due_time !== event.due_time) {
          event.zIndex = listOfOccuredEvents.length
          if (item.height > 20 && !item.leftPosition) {
            zIndex = item.zIndex
            item.leftPosition = zIndex
            // item.width = item.leftPosition !== 0 ? item.width - zIndex : zIndex
          } else {
            item.leftPosition = item.width < 96 && item.leftPosition ? item.leftPosition : item.leftPosition = 5
            // if (item.leftPosition === 5 && item.width === 96) item.width = item.width - 5
          }
        }
      })
    },
    getWeekDaysByDate (date) {
      const dateformat = 'YYYY-MM-DD'
      let singleDate = date ? this.$moment(date, dateformat) : this.$moment()
      let weeklength = 7
      const result = []
      singleDate = this.$moment(date).startOf('day')
      while (weeklength--) {
        result.push(this.$formatter.formatDate(singleDate, 'YYYY-MM-DD HH:MM:ss', 'YYYY-MM-DD 00:00'))
        singleDate.add(1, 'day')
      }
      this.weekDates = result
      return result
    },
    next () {
      this.$refs.calendar.fireMethod('next')
      this.setTitle()
      this.getEventsAndTasks()
      this.updatedEventsFromSelectedDate = false
      if (this.calendarView === 'month') this.getSelectedDateStyle()
    },
    today () {
      this.$refs.calendar.fireMethod('today')
      this.setTitle()
      this.getEventsAndTasks()
      if (this.calendarView === 'month') this.getSelectedDateStyle()
    },
    previous () {
      this.$refs.calendar.fireMethod('prev')
      this.setTitle()
      this.getEventsAndTasks()
      this.updatedEventsFromSelectedDate = false
      if (this.calendarView === 'month') this.getSelectedDateStyle()
    },
    setTitle () {
      const calendarObj = this.$refs.calendar.fireMethod('getView')
      this.calendarTitle = calendarObj.title
    },
    getSelectedDateEvents (selectedDate) {
      if (this.calendarView === 'month') {
        this.eventsOnSelectedDay = this.listOfAllEvents.filter((event) => {
          return (selectedDate)
            ? this.$formatter.formatDate(event.start_date, '', 'YYYY-MM-DD 00:00') === selectedDate ||
              this.$formatter.formatDate((event.end_date ? event.end_date : event.due_date), '', 'YYYY-MM-DD 00:00') === selectedDate ||
              this.$moment(selectedDate, 'YYYY-MM-DD 00:00').isBetween(this.$formatter.formatDate(event.start_date, '', 'YYYY-MM-DD 00:00'),
                this.$formatter.formatDate((event.end_date) ? event.end_date : event.due_date, '', 'YYYY-MM-DD 00:00'))
            : event
        })
      } else {
        this.eventsOnSelectedDay = this.listOfAllEvents.filter((event) => {
          const date = this.$formatter.cloneVariable(this.$formatter.formatDate(event.start_date, '', 'YYYY-MM-DD'))
          let endDate = ''
          let due = ''
          if (event.modulename === 'Event') {
            endDate = this.$formatter.cloneVariable(this.$formatter.formatDate(event.end_date, '', 'YYYY-MM-DD'))
          } else {
            due = this.$formatter.cloneVariable(this.$formatter.formatDate(event.due_date, '', 'YYYY-MM-DD'))
          }
          const dates = this.$formatter.enumerateDaysBetweenDates(this.$moment(date, 'YYYY-MM-DD'), endDate ? this.$moment(endDate, 'YYYY-MM-DD') : this.$moment(due, 'YYYY-MM-DD'))
          if (dates.length > 1) {
            return dates.includes(this.$formatter.formatDate(selectedDate, 'YYYY-MM-DD 00:00', this.userDetails.dateformat))
          } else {
            // return this.$formatter.fromUtcToLocal(event.start_date, 'DD.MM.YYYYTHH.mm.ss', 'YYYY-MM-DD 00:00') === selectedDate
            return this.$formatter.formatDate(event.start_date, '', 'YYYY-MM-DD 00:00') === selectedDate
          }
        })
        this.getHoursPerDay()
      }
    },
    expandDetailsAboutDate (event) {
      if (event) {
        this.selectedId = event._id
        this.recordAccessControl = event
        this.eventObj.type = event.modulename === 'Event' ? 1 : 2
        this.dialog = true
      }
    },
    setFormValues () {
      if (this.selectedId) {
        this.getSingleRecordHandler(this.selectedId, this.eventObj.type)
      }
    },
    getEventsAndTasks (filteredData) {
      if (this.calendarView === 'month') this.getSelectedDateStyle()
      if (!this.updatedEventsFromSelectedDate) this.selectedDate = ''
      this.eventsOnSelectedDay = []
      this.intialPageLoader = true
      this.isActiveAlert = false
      this.events = []
      this.customFilter = this.eventsType.find(x => x.id === filteredData)
      if (this.customFilter) this.customFilterName = this.customFilter.id
      if (filteredData) localStorage.setItem(`${process.env.VUE_APP_NAME}_calendar_filter`, JSON.stringify(filteredData))
      else {
        this.filterBy = localStorage.getItem(`${process.env.VUE_APP_NAME}_calendar_filter`)
        this.customFilter = this.eventsType.find(x => x.id === JSON.parse(this.filterBy))
        filteredData = JSON.parse(this.filterBy)
      }
      if (filteredData && this.calendarActiveModules.length !== 0) {
        if (this.calendarView !== 'month') {
          const startDateForWeekView = this.$refs.calendar.fireMethod('getView').start.format('YYYY-MM-DD 00:00')
          this.selectedDate = startDateForWeekView
          if (this.calendarView === 'agendaWeek') this.getWeekDaysByDate(startDateForWeekView)
          this.getSelectedDateEvents(this.selectedDate)
          this.getHoursPerDay()
        }
        let start = this.$formatter.cloneVariable(this.$refs.calendar.fireMethod('getView').start)
        start = this.$moment(start).subtract(1, 'days')
        let end = this.$refs.calendar.fireMethod('getView').end
        start = this.$formatter.formatMomentDate(start, 'YYYY-MM-DD HH:mm:ss')
        end = this.$formatter.formatMomentDate(end, 'YYYY-MM-DD HH:mm:ss')
        this.listOfAllEvents = []
        this.$api.execute('post', 'calendar/get_by_dates', { start, end, type: filteredData })
          .then(({ data }) => {
            this.constructCalendarData(data)
          }).finally(() => {
            this.intialPageLoader = false
          })
      } else {
        if (this.calendarView !== 'month') {
          this.selectedDate = this.$refs.calendar.fireMethod('getView').start.format('YYYY-MM-DD 00:00')
          if (this.calendarView === 'agendaWeek') this.getWeekDaysByDate(this.selectedDate)
          this.getSelectedDateEvents(this.selectedDate)
          this.getHoursPerDay()
        }
        this.isActiveAlert = true
        this.intialPageLoader = false
      }
    },
    constructCalendarData (events) {
      this.enumarateDates = []
      let listOfEvents = []
      events.forEach(element => {
        let date = ''
        let endDate = ''
        let due = ''
        date = this.$formatter.cloneVariable(this.$formatter.formatDate(element.start_date, '', 'YYYY-MM-DD'))
        if (element.modulename === 'Event') endDate = this.$formatter.cloneVariable(this.$formatter.formatDate(element.end_date, '', 'YYYY-MM-DD'))
        else due = this.$formatter.cloneVariable(this.$formatter.formatDate(element.due_date, '', 'YYYY-MM-DD'))
        const dates = this.$formatter.enumerateDaysBetweenDates(this.$moment(date, 'YYYY-MM-DD'), endDate ? this.$moment(endDate, 'YYYY-MM-DD') : this.$moment(due, 'YYYY-MM-DD'))
        this.listOfAllEvents.push(element)
        if (dates.length) {
          if (listOfEvents.length) {
            dates.forEach(item => {
              const alreadyExists = listOfEvents.find((x) => this.$formatter.formatDate(x.start, 'YYYY-MM-DD HH.mm', this.userDetails.dateformat) === item)
              if (!alreadyExists) {
                listOfEvents = this.getAllEvents(element, [item])
              }
            })
          } else {
            listOfEvents = this.getAllEvents(element, dates)
          }
        }
      })
      this.events = listOfEvents
      if (this.selectedDate === '') {
        // this.calendarView === 'month' ? this.selectedDate = '' : this.selectedDate = this.$formatter.formatDate(this.$refs.calendar.fireMethod('getView').start.add(1, 'days'), 'YYYY-MM-DD HH:mm:ss', 'DD.MM.YYYY')
        if (this.calendarView === 'month') this.selectedDate = ''
        else this.selectedDate = this.$formatter.formatDate(this.$refs.calendar.fireMethod('getView').start.add(1, 'days'), 'YYYY-MM-DD HH:mm:ss', this.userDetails.dateformat)
        this.selectedDateEvent = true
        this.getSelectedDateEvents(this.$formatter.formatDate(this.selectedDate, 'DD.MM.YYYYTHH.mm.ss', 'YYYY-MM-DD 00:00'))
      } else {
        this.getSelectedDateEvents(this.selectedDate)
      }
      this.loading = false
      this.intialPageLoader = false
      this.isActiveAlert = false
    },
    getAllEvents (element, dates) {
      dates.forEach((eventDate) => {
        eventDate = this.$formatter.formatDate(eventDate, this.userDetails.dateformat, 'YYYY-MM-DD')
        this.enumarateDates.push({
          start: `${eventDate + ' ' + (element.start_time ? element.start_time : '00:00')}`,
          end: `${element.modulename === 'Event' ? eventDate + ' ' + (element.end_at ? element.end_at : '23:59') : eventDate + ' ' + (element.due_time ? element.due_time : '23:59')}`,
          title: element.name,
          eventtype: element.modulename === 'Event' ? 1 : 2,
          color: `${this.calendarView !== 'month' ? `${element.modulename === 'Event' ? '#039BE5' : '#da1a6e'}` : ''}`,
          className: this.$vuetify.theme.dark ? ['event-dark-theme-color'] : this.calendarView === 'month' ? ['event-light-theme-color', 'display-event'] : ['apply-calendar-border'],
          id: element._id
        })
      })
      return this.enumarateDates
    },
    getFormattedDate (date) {
      return this.$formatter.formatDate(date, '', 'MMM DD')
    },
    getInBetweenDateCount ({ startdate, enddate }) {
      return this.$formatter.enumerateDaysBetweenDates(this.$moment(startdate, 'MMM DD'), this.$moment(enddate, 'MMM DD')).length || 0
    },
    setFieldsforSelectedDate (sDate, eDate, startTime, endTime) {
      this.fields = {
        ...this.fields,
        // start_date: sDate,
        // end_date: this.moduleObj.moduleName === 'Event' ? eDate : undefined,
        start_date: this.moduleObj.moduleName === 'Event' ? sDate + ' ' + startTime : sDate,
        end_date: this.moduleObj.moduleName === 'Event' ? eDate + ' ' + endTime : undefined,
        due_date: eDate,
        start_time: startTime,
        end_at: endTime,
        due_time: endTime
      }
      // const dueDate = this.listOfFields.filter(x => x.type === 6)
      // dueDate.map(x => {
      //   x.date_value = this.dateParse(this.fields[x.name])
      // })
      this.listOfFields.map((fieldItem) => {
        if (fieldItem.type === 6) {
          fieldItem.date_value = this.$formatter.formatDate(this.fields[fieldItem.name], this.userDetails.dateformat, 'YYYY-MM-DD')
        }
        if (fieldItem.type === 16) {
          // fieldItem.dateTimePicker = this.$formatter.formatDate(this.fields[fieldItem.name], '', 'YYYY-MM-DD')
          // fieldItem.timePicker = this.$formatter.formatDate(this.fields[fieldItem.name], '', 'HH:mm')
          fieldItem.dateTimePicker = this.$formatter.formatDate(this.fields[fieldItem.name], `${this.userDetails.dateformat} HH:mm`, 'YYYY-MM-DD')
          fieldItem.timePicker = this.$formatter.formatDate(this.fields[fieldItem.name], `${this.userDetails.dateformat} HH:mm`, 'HH:mm')
        }
      })
    },
    dateParse (date) {
      return this.$formatter.formatDate(date, this.userDetails.dateformat, 'YYYY-MM-DD')
    },
    getSingleRecordHandler (id, type) {
      // this.eventObj.type = type
      const moduleName = type === 1 ? this.EVENT : this.TASK
      this.updatedEventsFromSelectedDate = true
      this.getRecordHandler(`moduledata/${moduleName}/get_by_id/${id}`, 'calendar')
    },
    closeDialogHandler () {
      this.eventObj.type = null
      this.selectedId = ''
      this.dateHolder = {}
      this.dialog = false
    },
    saveUpdateHandler () {
      const moduleName = this.eventObj.type === 1 ? this.EVENT : this.TASK
      this.updateRecordHandler({ id: this.selectedId, moduleName, moduleId: this.moduleObj.moduleId, type: '', from: 'calendar' })
    },
    createEventOrTask (view, customDate, eventType, setEventTime) {
      if (eventType) this.eventObj.type = eventType
      else this.eventObj.type = this.calendarModules[0].id // removed commented for setting duetime based on event type
      this.dialog = true
      let startDate = ''
      let endDate = ''
      let startTime = ''
      let endTime = ''
      startDate = this.$formatter.formatDate(customDate ? this.$moment(customDate) : this.$moment(), 'DD.MM.YYYYTHH.mm.ss', this.userDetails.dateformat)
      endDate = this.$formatter.formatDate(customDate ? this.$moment(customDate) : this.$moment(), 'DD.MM.YYYYTHH.mm.ss', this.userDetails.dateformat)
      if (view === 'month') {
        // endDate = this.$formatter.formatDate(endDate, 'DD.MM.YYYYTHH.mm.ss', this.userDetails.dateformat)
        endDate = this.$formatter.formatDate(endDate, this.userDetails.dateformat, this.userDetails.dateformat)
        startTime = '00:00'
        endTime = '23:59'
        if (this.selectedDate) this.updatedEventsFromSelectedDate = true
      } else {
        startTime = setEventTime.time
        endTime = this.eventObj.type === 2 ? this.$moment(setEventTime.time, 'HH:mm').format('HH:mm') : this.$moment(setEventTime.time, 'HH:mm').add(1, 'hours').format('HH:mm')
        if (this.calendarModules.length > 0) {
          if (this.customFilter && this.customFilter.name && this.customFilter.id !== -1) this.eventObj.type = this.customFilter.id
          else this.eventObj.type = this.calendarModules[0].id
        }
      }
      this.dateHolder = { startDate, endDate, startTime, endTime }
    },
    getHoursPerDay () {
      const hours = []
      for (let hour = 0; hour < 24; hour++) {
        const formattedTime = this.$moment({ hour }).format('HH:mm')
        hours.push({ time: formattedTime, data: this.getEventsOfGivenTime(formattedTime) })
        hours.push({ time: this.$moment({ hour, minute: 30 }).format('HH:mm'), data: this.getEventsOfGivenTime(this.$moment({ hour, minute: 30 }).format('HH:mm')) })
      }
      hours.forEach((eventObj) => {
        if (eventObj.data.length > 1) {
          eventObj.data.forEach((x, idx) => {
            x.width = 96 / eventObj.data.length
            x.leftPosition = (x.width * idx)
          })
        }
      })
      this.getEventsBetweenHalfHour = hours
    },
    getEventsOfGivenTime (hour) {
      const endTempTime = this.$moment(hour, 'HH:mm').add(29, 'minutes').format('HH:mm')
      const eventSectionStartTime = this.$moment(hour, 'HH:mm')
      const eventSectionEndTime = this.$moment(endTempTime, 'HH:mm')
      const eventsOnDay = this.eventsOnSelectedDay.filter((event) => {
        if (event.start_time) {
          const minutes = this.$moment(event.end_at, 'HH:mm').diff(this.$moment(event.start_time, 'HH:mm'), 'minutes')
          const hours = minutes / 60
          event.height = hours * 42
          let startTimeMinutes = +(event.start_time.split(':')[1])
          if (startTimeMinutes >= 30) startTimeMinutes = startTimeMinutes - 30
          startTimeMinutes = +startTimeMinutes / 30
          event.topPosition = startTimeMinutes * 20
          const eventStartTime = this.$moment(event.start_time, 'HH:mm')
          return eventStartTime.isSameOrAfter(eventSectionStartTime) && eventStartTime.isSameOrBefore(eventSectionEndTime)
        } else {
          const minutes = this.$moment(event.due_time, 'HH:mm').diff(this.$moment('00:00', 'HH:mm'), 'minutes')
          const hours = minutes / 60
          event.height = hours * 43
          // let startTimeMinutes = event.due_time ? +(event.due_time.split(':')[1]) : ''
          // if (startTimeMinutes >= 30) startTimeMinutes = startTimeMinutes - 30
          // startTimeMinutes = +startTimeMinutes / 30
          event.topPosition = 0
          return this.$moment('00:00', 'HH:mm').isSameOrAfter(eventSectionStartTime) && this.$moment('00:00', 'HH:mm').isSameOrBefore(eventSectionEndTime)
        }
      })
      // const eventsToRemoveFromList = eventsOnDay.map(x => x._id)
      // this.eventsOnSelectedDay = this.eventsOnSelectedDay.filter(x => !eventsToRemoveFromList.includes(x._id))
      return eventsOnDay
    }
  },
  beforeDestroy () {
    this.$root.$off('callRerenderEventForm')
    this.$formatter.updateMomentLocale(this.$cookie.get(`${process.env.VUE_APP_NAME}_lang`))
  }
}
</script>
<style scoped>
@import '~fullcalendar/dist/fullcalendar.css';
</style>
<style>
  .agenda-week-custom-class .v-data-table__wrapper{
    height: calc(100vh - 232px) !important;
    overflow-y: auto;
  }
  .agenda-day-custom-class .v-data-table__wrapper {
    height: calc(100vh - 137px) !important;
    overflow-y: auto;
  }
  .display-alert-icon .v-alert__wrapper{
    display: inline-flex !important;
  }
  .display-alert-icon .v-alert--prominent .v-alert__icon {
    height: 39px !important;
    min-width: 39px !important;
    font-size: 24px !important;
  }
  .event-light-theme-color {
    background: #424242 !important;
    border: #424242 !important;
  }
  .event-dark-theme-color {
    background: #f8f8f8 !important;
    border: #f8f8f8 !important;
  }
  .calendar-page .v-btn--fab.v-size--x-small {
    height: 27px;
    width: 28px;
  }
  .calendar-page .v-card__text {
    min-height: unset !important;
  }
  .calendar-page .fc-basic-view .fc-week-number, .fc-basic-view .fc-day-number {
    padding: 6px !important;
  }
  .calendar-page .fc-ltr .fc-basic-view .fc-day-top .fc-day-number {
    margin-top: 4px !important;
    justify-content: center;
    display: flex;
    float: unset;
    border-width: thick;
    border-radius: 48px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3px !important;
  }
  /* checkbox size */
  .calendar-checkbox .v-input--selection-controls__input .v-icon {
    font-size: 19px !important;
  }
  .calendar-page .fc-day-top.fc-today .fc-day-number {
    background: #2142e4 !important;
    color: white !important;
    width: 20px;
    height: 20px;
  }
  .calendar-page .weekview-currentdate {
    background: #2142e4 !important;
    color: white !important;
    padding: 7px 8px;
    border-radius: 100%;
  }
  .cell-bg-change:not(.weekview-currentdate) {
    border: 2.5px solid #448AFF;
    border-radius: 100%;
    padding: 4px;
    width: 35px;
    margin: 4px auto;
    display: flex;
    justify-content: center;
  }
  .calendar-page .fc-time {
    color: #201e1e !important;
  }
  .calendar-page .fc-event {
    border-radius: 5px;
    width: 2px;
    height: 4px;
    background: rgb(231, 62, 0) !important;
    margin: auto;
    margin-top: 0px !important;
  }
  .calendar-page .fc-title {
    color: transparent !important;
  }
  .calendar-page .fc-basic-view .fc-body .fc-row {
    min-height: unset !important;
    /* text-decoration: underline !important; */
  }
  .calendar-page .fc-more-cell {
    display: none;
  }
  /* .calendar-page .fc-day-grid-event.fc-h-event.fc-event.fc-start.fc-end.fc-draggable {
    margin-left: auto;
    margin-right: auto;
    margin-top: -2px !important;
  } */
  .calendar-page .v-toolbar__content, .v-toolbar__extension {
    padding: unset !important;
  }
  .calendar-page .event-display-xs-device{
    height: calc(100vh - 402px) !important;
    /* margin-top: 1px !important; */
    overflow-y: auto !important;
    overflow-x: hidden !important;
  }
  .calendar-page .set-custom-height {
    height: calc(100vh - 432px) !important;
  }
  .calendar-page .event-display-large-device {
    height: calc(100vh - 570px) !important;
    padding: 3px !important;
    overflow-y: auto;
  }
  .calendar-page .v-dialog--fullscreen {
    overflow-x: hidden !important;
  }
  .radio-alignments {
    justify-content: center;
    display: flex;
  }
  .calendar-page.fc-day-number {
    padding-bottom: 6px !important;
  }
  .calendar-page .v-divider--vertical {
    border: solid;
    border-width: 0 2px 0 0;
    display: inline-flex;
  }
  .calendar-page ::-webkit-scrollbar {
    display: none !important;
  }
  .calendar-page .fc-view.fc-agendaWeek-view.fc-agenda-view {
    display: none;
  }
  .calendar-page .fc-scroller.fc-time-grid-container {
    overflow: hidden scroll;
  }
  /* .calendar-page .fc-time-grid .fc-event, .fc-time-grid .fc-bgevent {
    border-radius: unset !important;
    width: unset !important;
    height: unset !important;
    color: transparent;
  } */
  /* .calendar-page .v-navigation-drawer {
    height: calc(100vh - 118px) !important;
  } */
  .calendar-page .fc-highlight {
    background: unset !important;
    opacity: 20.4 !important;
    border-radius: 30px !important;
    width: 18px !important;
    height: 18px !important;
    margin-top: 4px !important;
    margin-bottom: 3px !important;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    border: 2px solid #0177fd !important;
    /* color: #201e1e !important; */
  }
  .calendar-page .fc-day-number.fc-highlight {
    padding-top: 4px !important;
  }
  .calendar_speed_dial_position {
    bottom: 65px !important;
    right: 1.7vh !important;
  }
  .form-height {
    height: calc(100vh - 60px) !important;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .calendar-page .fc-unthemed th, .fc-unthemed td {
    border: unset !important;
  }
  .calendar-page .v-timeline-item__dot--small {
    height: 13px !important;
    width: 15px !important;
    margin-left: auto !important;
    margin-right: auto !important;
    width: 12px !important;
  }
  .v-timeline-item__dot--small .v-timeline-item__inner-dot {
    height: 8px !important;
    margin-top: 10px !important;
    margin-left: auto !important;
    margin-right: auto !important;
    width: 8px !important;
  }
  .set-height {
    height: calc(100vh - 125px) !important;
    overflow-y: auto;
  }
  .calendar-page .v-timeline--dense .v-timeline-item__opposite {
    display: block !important;
  }
  .calendar-page .v-timeline-item__opposite {
    flex: unset !important;
  }
  .calendar-page .v-timeline--dense:not(.v-timeline--reverse):before {
    left: calc(48px + 25px) !important;
  }
  .calendar-page .v-timeline-item__divider {
    min-width: 45px !important;
  }
  .calendar-page .v-timeline-item__divider {
  align-items: flex-start !important;
  }
  .calendar-timeline {
    height: calc(100vh - 212px) !important;
    overflow-y: auto !important;
  }
  .remove-cell-bg {
    background: unset !important;
  }
  /* added to remove the time line's extra starting and ending line */
  .calendar-timeline .v-timeline:before {
    /* height: calc(100% - 57px) !important; removed for showing 24 hours event */
    top: 25px !important;
  }
  .calendar-timeline .v-alert__border {
    border-width: 2px !important;
  }
  .calendar-timeline .v-timeline--dense .v-timeline-item {
    height: 50px !important
  }
  .calendar-timeline .v-timeline-item__body {
    border: ridge !important;
  }
  .td-boder-left {
    border-right: 1px solid #ddd !important;
  }
  .agenda-week-custom-class .v-data-table > .v-data-table__wrapper > table {
    border-block: 1px solid #ddd !important;
  }
  .agenda-week-custom-class .get-solid-border {
    border-top: 1px solid #ddd !important;
  }
  .agenda-day-custom-class .get-solid-border {
      border-top: 1px solid #ddd !important;
  }
  .agenda-week-custom-class .apply-event-style {
    background: #0177fd !important;
  }
  .grid-style {
    position: relative !important;
  }
  .calendar-page .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    background: unset !important;
  }
  .calendar-page .create-event:active {
    border: solid #0033cc !important
  }
  .calendar-page .set-inner-table-height {
    height: unset !important;
  }
  .agenda-week-custom-class .custom-event-container {
    margin: 0 5% 0 2px !important;
    z-index: 2;
    width: 45vh !important;
  }
  .common-background {
    background: #FFFFFF !important;
  }
</style>
